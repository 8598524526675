import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'

//
;(async function () {
	const App = await import(`./components/App`).then((m) => m.default)

	const render = !!module.hot ? ReactDOM.render : ReactDOM.hydrate

	const app = render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
		document.getElementById('root'),
	)

	if (!!module.hot) return app

	loadableReady(() => app)
})()
